import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import { rhythm } from '../utils/typography'
import { PageWidths } from '../utils/layoutUtils'
import ReadingTime from '../components/readingTime'

class Exams extends React.Component {
  render() {
    const {
      data: { site, blogPosts },
      location,
    } = this.props
    const siteTitle = site.siteMetadata.title
    const posts = blogPosts.edges

    return (
      <Layout location={location} title={siteTitle} pageWidth={PageWidths.WIDE}>
        <SEO title="Blog" url="/blog/" />
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          `}
        >
          {posts.map(({ node: post }) => {
            const title = post.frontmatter.title || post.fields.slug
            const readingTime = post.fields.readingTime.minutes

            return (
              <article
                key={post.fields.slug}
                css={css`
                  max-width: ${rhythm(10.5)};
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
                  margin: ${rhythm(0.25)};
                  margin-bottom: ${rhythm(2)};
                  padding: ${rhythm(0.5)};
                  border-radius: 5px;
                `}
              >
                <header>
                  <h3
                    css={css`
                      margin-top: ${rhythm(0.25)};
                    `}
                  >
                    <Link style={{ boxShadow: `none` }} to={post.fields.slug}>
                      <div
                        css={css`
                          height: 120px;
                        `}
                      >
                        {title}
                      </div>
                      {post.frontmatter.banner && (
                        <Banner
                          frontmatter={post.frontmatter}
                          style={{ marginLeft: 0, marginRight: 0, marginTop: `1.7rem` }}
                        />
                      )}
                    </Link>
                  </h3>
                </header>
                <section>
                  <ReadingTime readingTimeInMinutes={readingTime} />
                  <p
                    css={css`
                      font-size: 0.9rem;
                    `}
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                  />
                </section>
              </article>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export default Exams

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blogPosts: allMdx(
      filter: {
        frontmatter: {published: {ne: false}, category: {eq: "AWS Certification"}}
        fileAbsolutePath: {regex: "//src/content/blog//"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            readingTime {
              minutes
            }
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            description
            banner {
              ...bannerImageSmall
            }
          }
        }
      }
    }
  }
`
